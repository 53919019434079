import React, { useEffect, useState } from "react";
import "./Home.css";
import { WhyDoorCloosers, Hero, WeCanGetYou } from "../../sections/index";
import {
  BackGroundAnimationColor,
  ContactForm,
  Calendar,
} from "../../components/index";
import { analytics } from "../../config/firebase";

const Home = () => {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    // if (analytics) {
    //   analytics.logEvent("screen_view", {
    //     firebase_screen: "TEST",
    //     firebase_screen_class: "ReactApp",
    //   });
    // }
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 884);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!isTablet && <BackGroundAnimationColor />}
      <Hero />
      <WeCanGetYou />
      <WhyDoorCloosers />
      <section className="container d-flex flex-column align-items-center text-white mt-5 width-100">
        <h1 className="header-under-line responsive-text-60 text-white text-center">
          Send Us A Message
        </h1>
        <h2 className="responsive-text-48 text-center text-white mt-4">
          Tell Us About Yourself And Your Business.
          <br />
          We’d Love To Help You!
        </h2>
        <ContactForm page="home" />
        <h2
          className="responsive-text-48 text-center font-light text-white mt-4"
          id="form"
        >
          Let Us Give You A Demo With No Strings Attached
        </h2>
        <h2 className="responsive-text-48 text-center text-white mt-4">
          You’ll Be Able To See How We Can Help You
          <br />
          Maximize Productivity.
        </h2>
      </section>
      <Calendar />
    </>
  );
};

export default Home;
