import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocation } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Development.css";
import "swiper/css/effect-fade";
import { BsBoxArrowUpLeft } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import calenderImg from "../../assets/images/calendar.png";
import manImg from "../../assets/images/Door-man-F2.gif";
import integration from "../../assets/images/integration.png";
import crm from "../../assets/images/crm.png";
import webhooks from "../../assets/images/webhooks.png";
import development from "../../assets/images/development-dev.png";
import reports from "../../assets/images/reports.png";
import dashboard from "../../assets/images/dashboard.png";
import website1 from "../../assets/images/website1.png";
import website2 from "../../assets/images/website2.png";
import website3 from "../../assets/images/website3.png";
import website4 from "../../assets/images/website4.png";
import {
  BackGroundAnimationColor,
  Calendar,
  ContactForm,
} from "../../components/index";
import { HashLink } from "react-router-hash-link";

const paginationWords = [
  "Websites",
  "CRMs",
  "Development",
  "Dashboards",
  "Reports",
  "Webhooks",
  "Integration",
  "Calendar",
];
const mainSlideExpandedClass = "main-slide-expanded";

const pagination = {
  clickable: true,
  renderBullet: function (index, className) {
    return (
      '<span class="' + className + '">' + paginationWords[index] + "</span>"
    );
  },
};

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
  const yOffset = window.innerWidth <= 884 ? -70 : -120;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

function Development() {
  const isTablet = window.innerWidth <= 884;
  const swiperRef = useRef(null);
  const location = useLocation();
  const [expandedContent, setExpandedContent] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const slideIndex = parseInt(queryParams.get("slide"), 10);

    if (swiperRef.current && !isNaN(slideIndex)) {
      swiperRef.current.slideTo(slideIndex);
    }
  }, [location]);

  useEffect(() => {
    const handleSlideChange = () => {
      setExpandedContent(null);
      document
        .querySelectorAll(`.${mainSlideExpandedClass}`)
        .forEach((slide) => {
          slide.classList.remove(mainSlideExpandedClass);
        });
    };

    if (swiperRef.current) {
      swiperRef.current.on("slideChange", handleSlideChange);
    }

    return () => {
      if (swiperRef.current) {
        swiperRef.current.off("slideChange", handleSlideChange);
      }
    };
  }, []);

  return (
    <>
      {!isTablet && <BackGroundAnimationColor />}

      <section className="mainSection">
        <HashLink
          smooth
          to="/development#calendar"
          scroll={scrollWithOffset}
          className="calenderLink"
        >
          <img src={calenderImg} alt="Calendar" />
        </HashLink>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <img src={manImg} alt="" className="manImg" />
            </div>
          </div>
        </div>

        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          slidesPerView={1}
          spaceBetween={30}
          pagination={pagination}
          navigation={true}
          loop={true}
          modules={[Navigation, Pagination]}
          className={`mySwiper ${
            expandedContent !== null ? mainSlideExpandedClass : ""
          }`}
        >
          <SwiperSlide className="mainSlide">
            <div>
              <div
                data-animate="bottom"
                className="contentMain animeslide-desc"
              >
                <h3>What can we offer?</h3>
                <div className="content websitess">
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    effect={"fade"}
                    modules={[Autoplay, EffectFade]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <a
                        href="https://app.gohighlevel.com/v2/preview/3jQ6VeOVV4yPIS0WQATH"
                        target="_blank"
                        className="link"
                      >
                        <img src={website1} alt="" className="imgDifferw" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide>
                      <a
                        href="https://extronnect.com/"
                        target="_blank"
                        className="link"
                      >
                        <img src={website2} alt="" className="imgDifferw" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide>
                      <a
                        href="https://consumer-protection-networks.web.app/"
                        target="_blank"
                        className="link"
                      >
                        <img src={website3} alt="" className="imgDifferw" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide>
                      <a
                        href="https://app.gohighlevel.com/v2/preview/9lWttqUfnrjvw8oyPG7J"
                        target="_blank"
                        className="link"
                      >
                        <img src={website4} alt="" className="imgDifferw" />
                      </a>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <div className="title">
                <h2>Websites</h2>
              </div>
            </div>
          </SwiperSlide>

          {/* Repeat SwiperSlide for other slides with similar modifications */}
          <SwiperSlide className={`mainSlide`}>
            <div>
              <div
                data-animate="bottom"
                className="contentMain animeslide-desc"
              >
                <h3>What can we offer?</h3>
                <div className={`content`}>
                  <img src={crm} alt="" />
                </div>
              </div>
              <div className="title">
                <h2>CRMs</h2>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className={`mainSlide`}>
            <div>
              <div
                data-animate="bottom"
                className="contentMain animeslide-desc"
              >
                <h3>What can we offer?</h3>
                <div className={`content `}>
                  <img src={development} alt="" className="imgDiffer" />
                </div>
              </div>
              <div className="title">
                <h2>Development</h2>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className={`mainSlide `}>
            <div>
              <div
                data-animate="bottom"
                className="contentMain animeslide-desc"
              >
                <h3>What can we offer?</h3>
                <div className={`content`}>
                  <img src={dashboard} alt="" className="imgDiffer" />
                </div>
              </div>
              <div className="title">
                <h2>Dashboards</h2>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide
            className={`mainSlide ${
              expandedContent === 4 ? mainSlideExpandedClass : ""
            }`}
          >
            <div>
              <div
                data-animate="bottom"
                className="contentMain animeslide-desc"
              >
                <h3>What can we offer?</h3>
                <div className={`content `}>
                  <img src={reports} alt="" />
                </div>
              </div>
              <div className="title">
                <h2>Reports</h2>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className={`mainSlid`}>
            <div>
              <div
                data-animate="bottom"
                className="contentMain animeslide-desc"
              >
                <h3>What can we offer?</h3>
                <div className={`content`}>
                  <img src={webhooks} alt="" className="imgDiffer" />
                </div>
              </div>
              <div className="title">
                <h2>Webhooks</h2>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className={`mainSlide`}>
            <div>
              <div
                data-animate="bottom"
                className="contentMain animeslide-desc"
              >
                <h3>What can we offer?</h3>
                <div className={`content`}>
                  <img src={integration} alt="" />
                </div>
              </div>
              <div className="title">
                <h2>Integration</h2>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      <section
        className="container d-flex flex-column align-items-center text-white mt-5 "
        id="form"
      >
        <h1 className="responsive-text-60 text-white text-center">
          Want to estimate a project?
        </h1>
        <h2 className="responsive-text-45 text-center text-white mt-4">
          Just tell us about your project, your goals, and let’s start!
        </h2>
        <ContactForm page="development" />
      </section>
      <Calendar />
    </>
  );
}

export default Development;
