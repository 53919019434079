import { useState } from "react";
import "./ContactForm.css";
import { db, storage } from "../../../config/firebase";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FiUpload } from "react-icons/fi";
const Preloader = () => <div class="loader"></div>;
const ContactForm = (props) => {
  const [formData, setFormData] = useState({});
  const [file, setFile] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    if (event.target.name === "file") {
      setFile(event.target.files[0]);
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString("en-US");
    const year = new Date(formattedDate).getFullYear();
    const month = (new Date(formattedDate).getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const day = new Date(formattedDate).getDate().toString().padStart(2, "0");

    const dateWithoutAmPm = formattedDate.slice(0, -3);
    const amPm = formattedDate.slice(-3);
    const milliseconds = currentDate
      .getMilliseconds()
      .toString()
      .padStart(3, "0");

    const formattedDateWithMilliseconds = `${dateWithoutAmPm}.${milliseconds}${amPm}`;
    const docName = `${day}_${month}_${year}`;
    console.log(file);
    if (file) {
      const storageRef = ref(
        storage,
        `/files/${
          formattedDateWithMilliseconds.replaceAll("/", "_") + file.name
        }`
      );
      try {
        await uploadBytes(storageRef, file);
        var url = await getDownloadURL(storageRef);
        formData["fileUrl"] = url;
      } catch (error) {
        console.error(error);
      }
    }

    const uid = formattedDateWithMilliseconds
      .replaceAll("/", "_")
      .replaceAll(":", "-")
      .replace(", ", "@")
      .replace(" ", "#");

    formData["page"] = props.page;
    const submitData = {
      [uid]: formData,
    };

    await setDoc(doc(db, "submissions", docName), submitData, {
      merge: true,
    });
    // addDoc(collection(db, "mail"), {
    //   to: formData.email,
    //   message: {
    //     subject: "THX", // TODO: ADD CONTENT OF MAIL
    //     html: "thank you for your submission",
    //   },
    // });
    addDoc(collection(db, "mail"), {
      to: "info@doorclosers.org",
      message: {
        subject: "New Inquiry",
        html:
          props.page === "contact"
            ? `<code><h1>New Inquiry</h1><h2>Name: ${formData.name}</h2><h2>Email: ${formData.email}</h2><h2>Phone: ${formData.phone}</h2><h2>Company: ${formData.company}</h2><h2>Subject: ${formData.subject}</h2><h2>Message: ${formData.message}</h2></code>`
            : `<code><h1>New Inquiry</h1><h2>Name: ${formData.name}</h2><h2>Email: ${formData.email}</h2><h2>Where did you hear about us?: ${formData.whereDidYouHear}</h2><h2>Tell us about your project: ${formData.project}</h2><h2>Attach File Url: ${formData.fileUrl}</h2><h2>Message: ${formData.message}</h2></code>`,
      },
    });
    document.getElementById("contactForm").reset();
    setFile();
    setIsSubmitting(false);
  };

  return (
    <>
      <form className="form-container" id="contactForm" onSubmit={handleSubmit}>
        <div className="custom-input-container">
          <input
            type="text"
            name="name"
            className="custom-input"
            placeholder="Name:"
            required
            onChange={handleChange}
          />
        </div>
        <div className="custom-input-container">
          <input
            type="email"
            name="email"
            className="custom-input"
            placeholder="Email:"
            required
            onChange={handleChange}
          />
        </div>
        {props.page === "contact" ? (
          <>
            <div className="custom-input-container">
              <input
                type="text"
                name="company"
                className="custom-input"
                placeholder="Company:"
                onChange={handleChange}
              />
            </div>
            <div className="custom-input-container">
              <input
                type="tel"
                name="phone"
                className="custom-input"
                placeholder="Phone:"
                onChange={handleChange}
              />
            </div>
            <div className="custom-input-container">
              <input
                type="text"
                name="subject"
                className="custom-input"
                placeholder="Subject:"
                required
                onChange={handleChange}
              />
            </div>
          </>
        ) : (
          <>
            <div className="custom-input-container">
              <input
                type="text"
                name="whereDidYouHear"
                className="custom-input"
                placeholder="Where did you hear about us?:"
                onChange={handleChange}
              />
            </div>
            <div className="custom-input-container">
              <input
                type="text"
                name="project"
                className="custom-input"
                placeholder="Tell us about your project:"
                onChange={handleChange}
              />
            </div>
            <div className="custom-input-container">
              <input
                type="file"
                name="file"
                id="fileInput"
                className="custom-input"
                placeholder="Attach File:"
                required
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <label htmlFor="fileInput" className="custom-input">
                {file ? (
                  <div className="file-name">{file.name}</div>
                ) : (
                  <div style={{ color: "#757575", height: "100%" }}>
                    <p className="d-flex align-items-center height-100">
                      <FiUpload />
                      &nbsp; Attach files:
                    </p>
                  </div>
                )}
              </label>
            </div>
          </>
        )}

        <div className="custom-input-container">
          <textarea
            type="text"
            name="message"
            className="custom-input textarea"
            placeholder="Send Message:"
            required
            onChange={handleChange}
          />
        </div>
        <button
          type="submit"
          id="submitButton"
          className="custom-btn"
          disabled={isSubmitting}
        >
          {isSubmitting ? <Preloader /> : "Submit"}
        </button>
      </form>
    </>
  );
};

export default ContactForm;
