import React, { useEffect, useState } from "react";
import "./Header.css";
import Border from "../../assets/images/Door-Closers-border.png";
import Key from "../../assets/images/Door-Closers-key.png";
import Body from "../../assets/images/Door-Closers-body.png";
import LogoTxt from "../../assets/images/Get-In-Touch.svg";
import Logo from "../../assets/images/logo.png";
import { NavBarLink } from "../../components/index";
import { HashLink } from "react-router-hash-link";
import { useLoading } from "../../components/Global/LoadingScreen/LoadingContext";
import {
  Navbar,
  Nav,
  Button,
  Offcanvas,
  OffcanvasBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
  const yOffset = window.innerWidth <= 884 ? -70 : -120;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const Header = () => {
  const { setLoading } = useLoading();

  const handleClick = () => {
    setLoading(true);
    toggle();
  };
  const handleLoading = () => {
    setLoading(true);
  };

  const navItemsHow = [
    { label: "Websites", href: "development?slide=0#" },
    { label: "Appointments", href: "appointments#" },
    { label: "Leads", href: "leads#" },
    { label: "Marketing Campaigns", href: "outsourcing#" },
    { label: "CRMs", href: "development?slide=1#", isNew: true },
  ];
  const navItemsServices = [
    { label: "Development", href: "development?slide=2#" },
    { label: "Solar", href: "appointments#solar" },
    { label: "Real Estate", href: "appointments#realestate" },
    { label: "Marketing Agencies", href: "appointments#marketing" },
  ];
  const navItemsElevate = [
    { label: "Support", href: "contact#form" },
    { label: "Integrations", href: "development?slide=6#" },
    { label: "Team", href: "about#" },
    { label: "Virtual Assistants", href: "leads#" },
  ];
  const navItemsBusiness = [
    { label: "Expand Portfolio", href: "outsourcing#expand-portofolio" },
    { label: "Delegate Operations", href: "outsourcing#delegate-operations" },
    { label: "Increase Outreach", href: "outsourcing#increase-outreach" },
    { label: "Build a Team", href: "outsourcing#build-team" },
  ];
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 884);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const renderDropdownItems = (items) => {
    return items.map((item, index) => (
      <DropdownItem>
        <HashLink
          smooth
          to={`/${item.href}`}
          scroll={scrollWithOffset}
          className="footer-link-items text-black"
          onClick={handleClick}
          key={index}
        >
          {item.label}
          {item.isNew && <span className="badge badge-info">New</span>}
        </HashLink>
      </DropdownItem>
    ));
  };
  return (
    <>
      {!isTablet ? (
        <>
          <nav className="nav-bar-container">
            <div className="nav-bar">
              <HashLink
                smooth
                to="/#"
                className="nav-bar-logo"
                onClick={handleLoading}
              >
                <img src={Body} alt="DC Body" className="logo-part dc-body" />
                <HashLink smooth to="/contact" onClick={handleLoading}>
                  <img
                    src={LogoTxt}
                    alt="Logo Text"
                    className="logo-part dc-txt"
                  />
                </HashLink>
                <img
                  src={Border}
                  alt="DC Border"
                  className="logo-part dc-border"
                />
                <img src={Key} alt="DC Key" className="logo-part dc-key" />
              </HashLink>
              <div className="nav-bar-links-container">
                <div className="nav-bar-line" />
                <NavBarLink
                  title="How"
                  // to=" "
                  items={navItemsHow}
                  titleClassName="nav-bar-link-1"
                  containerClassName="line-after-container"
                />
                <NavBarLink
                  title="Our Services"
                  // to=" "
                  items={navItemsServices}
                  titleClassName="nav-bar-link-2"
                  containerClassName="line-after-container"
                />
                <NavBarLink
                  title="Elevate"
                  // to=" "
                  items={navItemsElevate}
                  titleClassName="nav-bar-link-3"
                  containerClassName="line-after-container"
                />
                <NavBarLink
                  title="Your Business"
                  // to=" "
                  items={navItemsBusiness}
                  titleClassName="nav-bar-link-4"
                />
              </div>
            </div>
          </nav>
        </>
      ) : (
        <>
          <Navbar sticky="top">
            <HashLink smooth to="/#" onClick={handleLoading}>
              <img src={Logo} alt="DC Logo" className="logo" />
            </HashLink>
            <Button onClick={toggle} className="ms-auto" color="transpernt">
              <svg
                height="28px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#fff"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M4 6H20M4 12H20M4 18H20"
                    stroke="var(--color-primary)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </g>
              </svg>
            </Button>
          </Navbar>
          <Offcanvas
            isOpen={isOpen}
            toggle={toggle}
            direction="top"
            className="offcanvas-auto-height"
          >
            <OffcanvasBody>
              <Nav className="ml-auto" navbar>
                <UncontrolledDropdown className="ml-auto" nav inNavbar>
                  <DropdownToggle nav caret>
                    How
                  </DropdownToggle>
                  <DropdownMenu end>
                    {renderDropdownItems(navItemsHow)}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Our Services
                  </DropdownToggle>
                  <DropdownMenu end>
                    {renderDropdownItems(navItemsServices)}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Elevate
                  </DropdownToggle>
                  <DropdownMenu end>
                    {renderDropdownItems(navItemsElevate)}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Your Business
                  </DropdownToggle>
                  <DropdownMenu end>
                    {renderDropdownItems(navItemsBusiness)}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </OffcanvasBody>
          </Offcanvas>
        </>
      )}
    </>
  );
};

export default Header;
