import React, { useEffect, useState } from "react";
import "./Leads.css";
import { BackGroundAnimationColor, Calendar } from "../../components/index";
import BatManRight from "../../assets/images/batman-right.png";
import BatManLeft from "../../assets/images/batman-left.png";

const Leads = () => {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 884);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {!isTablet && <BackGroundAnimationColor />}
      <section
        className="container d-flex flex-column align-items-center first-section-padding text-white"
        id="leads"
      >
        <h1 className="header-under-line responsive-text-48 text-white text-center">
          TO GET MORE <span className="highlight">LEADS</span> YOU CAN DO THIS
        </h1>
        <br />
        <ol>
          <li className="responsive-text-45">Hire VAs</li>
          <li className="responsive-text-45">Build A Team</li>
          <li className="responsive-text-45">Build A CRM</li>
          <li className="responsive-text-45">Keep Track Of Your Data</li>
          <li className="responsive-text-45">
            Get A Manager To Delegate Your Work
          </li>
          <li className="responsive-text-45">
            Build Quality Control Parameters
          </li>
          <li className="responsive-text-45">Create a Training Archive</li>
          <li className="responsive-text-45">
            Meet Consistently With Your Team
          </li>
          <li className="responsive-text-45">Handle Payrolls</li>
          <li className="responsive-text-45">Worry About Compliance</li>
        </ol>
        <br />
        <h1 className="responsive-text-48 width-100">SCENARIO #1</h1>
        <br />
        <h2 className="responsive-text-48 text-white width-100">
          Simply one of two scenarios will happen next
        </h2>
        <ol className="width-100">
          <li className="responsive-text-45">Automations start kicking in</li>
          <li className="responsive-text-45">You start delegating your work</li>
          <li className="responsive-text-45">
            Production goes on autopilot, followed by sales
          </li>
        </ol>
        <h2 className="responsive-text-48 text-white width-100">
          Nothing to do more at this point but Expanding
          <span className="highlight"> ;)</span>
        </h2>
        <br />
        <br />
        <h1 className="responsive-text-48 width-100 mt-5">SCENARIO #2</h1>
        <br />
        <ol className="width-100">
          <li className="responsive-text-45">Maintaining ongoing workloads</li>
          <li className="responsive-text-45">Doing Most Legwork</li>
          <li className="responsive-text-45">Production goes inconsistently</li>
        </ol>
        <h2 className="responsive-text-48 text-white width-100">
          At this point, your business will be struggling.
          <br />
          68% of lead generation dependent businesses suffer from Scenario #2
          <span className="highlight"> ;(</span>
        </h2>
        <br />
        <br />
        <div className="BatManLeft">
          <img src={BatManLeft} alt="BatMan Left" />
        </div>
        <div className="BatManRight">
          <img src={BatManRight} alt="BatMan Right" />
        </div>
      </section>
      <Calendar />
    </>
  );
};

export default Leads;
