import React from "react";
import "./Footer.css";
import footerLogo from "../../assets/images/footer-logo.png";
import { HashLink } from "react-router-hash-link";
import { useLoading } from "../../components/Global/LoadingScreen/LoadingContext";

const Footer = () => {
  const isResponsivDevice = window.innerWidth <= 990;
  const { setLoading } = useLoading();
  const handleLoading = () => {
    setLoading(true);
  };
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = window.innerWidth <= 884 ? -70 : -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div className="footer mt-5">
      <div className="footer-social-container mt-5">
        <div className="col-auto d-flex align-items-center me-4">
          <a
            href="https://www.facebook.com/doorcloserss"
            target="_blank"
            rel="noreferrer"
          >
            <div className="facebook-footer-icon footer-icon-container"></div>
          </a>
        </div>
        <div className="col-auto d-flex align-items-center me-4">
          <a
            href="https://www.instagram.com/doorclosers_"
            target="_blank"
            rel="noreferrer"
          >
            <div className="instagram-footer-icon footer-icon-container"></div>
          </a>
        </div>
        <div className="col-auto d-flex align-items-center me-4">
          <a
            href="https://x.com/doorclosers_?s=09"
            target="_blank"
            rel="noreferrer"
          >
            <div className="twitter-footer-icon footer-icon-container"></div>
          </a>
        </div>
        <div className="col-auto d-flex align-items-center me-4">
          <a
            href="https://www.linkedin.com/company/door-closers-cc"
            target="_blank"
            rel="noreferrer"
          >
            <div className="linkedin-footer-icon footer-icon-container"></div>
          </a>
        </div>
        <div className="col-auto d-flex align-items-center me-4">
          <a href="mailto:info@doorclosers.org">
            <div className="email-footer-icon footer-icon-container"></div>
          </a>
        </div>
        <div className="col-auto d-flex align-items-center">
          <a href="https://wa.me/201017818940" target="_blank" rel="noreferrer">
            <div className="whatsapp-footer-icon footer-icon-container"></div>
          </a>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-lg-2 col-md-4 mt-3 ">
          <h3 className="footer-link">Leads</h3>
          <HashLink
            smooth
            to="/leads#"
            className="footer-link-items"
            onClick={handleLoading}
          >
            Real Estate
          </HashLink>
          <HashLink
            smooth
            to="/leads#"
            className="footer-link-items"
            onClick={handleLoading}
          >
            PPC
          </HashLink>
          <HashLink
            smooth
            to="/leads#"
            className="footer-link-items"
            onClick={handleLoading}
          >
            Lead Generation
          </HashLink>
          <HashLink
            smooth
            to="/leads#"
            className="footer-link-items"
            onClick={handleLoading}
          >
            Cold Outreach
          </HashLink>
          <HashLink
            smooth
            to="/leads#"
            className="footer-link-items"
            onClick={handleLoading}
          >
            SMS Campaigns
          </HashLink>
          <HashLink
            smooth
            to="/leads#"
            className="footer-link-items"
            onClick={handleLoading}
          >
            Email Campaigns
          </HashLink>
          <HashLink
            smooth
            to="/leads#"
            className="footer-link-items"
            onClick={handleLoading}
          >
            Acquisition Managers
          </HashLink>
          <HashLink
            smooth
            to="/leads#"
            className="footer-link-items"
            onClick={handleLoading}
          >
            Virtual Assistants
          </HashLink>
          <HashLink
            smooth
            to="/leads#"
            className="footer-link-items"
            onClick={handleLoading}
          >
            Admin Virtual Assistants
          </HashLink>
          <HashLink
            smooth
            to="/leads#"
            className="footer-link-items"
            onClick={handleLoading}
          >
            Management
          </HashLink>
          <HashLink
            smooth
            to="/leads#"
            className="footer-link-items"
            onClick={handleLoading}
          >
            Supporting Functions
          </HashLink>
        </div>
        <div className="col-lg-2 col-md-4 mt-3 ">
          <h3 className="footer-link">Appointments</h3>
          <HashLink
            smooth
            to="/appointments#outreach"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Solar Appointments
          </HashLink>
          <HashLink
            smooth
            to="/appointments#outreach"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Roofing Appointments
          </HashLink>
          <HashLink
            smooth
            to="/appointments#outreach"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Appointment Setters
          </HashLink>
          <HashLink
            smooth
            to="/appointments#outreach"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Personal Assistants
          </HashLink>
          <HashLink
            smooth
            to="/appointments#outreach"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Admin Assistants
          </HashLink>
          <HashLink
            smooth
            to="/contact#calendar"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            CRM Managers
          </HashLink>
          <HashLink
            smooth
            to="/contact#calendar"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Data Entry Managers
          </HashLink>
        </div>
        <div className="col-lg-2 col-md-4 mt-3 ">
          <h3 className="footer-link">Development</h3>
          <HashLink
            smooth
            to="/development#form"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Websites
          </HashLink>
          <HashLink
            smooth
            to="/development#form"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            APIs & Webhooks
          </HashLink>
          <HashLink
            smooth
            to="/development#form"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Dashboards
          </HashLink>
          <HashLink
            smooth
            to="/development#form"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            CRMs
          </HashLink>
          <HashLink
            smooth
            to="/development#form"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Web development
          </HashLink>
          <HashLink
            smooth
            to="/development#form"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Web Design
          </HashLink>
        </div>
        <div className="col-lg-2 col-md-4 mt-3">
          <h3 className="footer-link">Contact us</h3>
          <HashLink
            smooth
            to="/contact#"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Email
          </HashLink>
          <HashLink
            smooth
            to="/contact#"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Meet today
          </HashLink>
          <HashLink
            smooth
            to="/contact#calendar"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Hop on a Call
          </HashLink>
          <HashLink
            smooth
            to="/contact"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Have a question?
          </HashLink>
        </div>
        <div className="col-lg-2 col-md-4 mt-3">
          <h3 className="footer-link">About</h3>
          <HashLink
            smooth
            to="/about#work"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Our Work
          </HashLink>
          <HashLink
            smooth
            to="/about#team"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Our Team
          </HashLink>
          <HashLink
            smooth
            to="/about#goal"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Our Goal
          </HashLink>
          <HashLink
            smooth
            to="/about#vision"
            scroll={scrollWithOffset}
            className="footer-link-items"
            onClick={handleLoading}
          >
            Our vision
          </HashLink>
        </div>
      </div>
      <div className="row mt-4 footer-info">
        <a
          style={{ paddingLeft: isResponsivDevice ? "10px" : "120px" }}
          href="tel:+201017818940"
        >
          +201 017-818-940
        </a>
        <a
          style={{ paddingLeft: isResponsivDevice ? "10px" : "120px" }}
          href="tel:+13172039363"
        >
          +1 (317) 203-9363
        </a>
        <p style={{ paddingLeft: isResponsivDevice ? "10px" : "120px" }}>
          3550 Moller Rd, Indianapolis, IN 46224
        </p>
      </div>
      <div className="mt-4">
        <p className="d-flex justify-content-center company-name">
          DOOR CLOSERS
        </p>
        <div className="d-flex justify-content-center">
          <img src={footerLogo} alt="dc logo" className="dc-logo" />
        </div>
        <p className="d-flex justify-content-center footer-privacy">
          Copyright &copy; 2024 Door Closers, LLC
        </p>
        <p className="d-flex justify-content-center footer-privacy">
          Designed by Door Closers
        </p>
        <div className="d-flex justify-content-center footer-privacy">
          <HashLink
            smooth
            to="/privacy#"
            scroll={scrollWithOffset}
            onClick={handleLoading}
          >
            Privacy policy
          </HashLink>
          <HashLink
            smooth
            to="/fulfillment#"
            scroll={scrollWithOffset}
            className="mx-2"
            onClick={handleLoading}
          >
            Fulfillment Policy
          </HashLink>
          <HashLink
            smooth
            to="/terms#"
            scroll={scrollWithOffset}
            onClick={handleLoading}
          >
            Terms & Conditions
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default Footer;
