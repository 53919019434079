import React, { useEffect, useState } from "react";
import "./Contact.css";
import {
  BackGroundAnimationColor,
  Calendar,
  ContactForm,
} from "../../components/index";

const Leads = () => {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 884);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {!isTablet && <BackGroundAnimationColor />}
      <section className="container d-flex flex-column align-items-center first-section-padding text-white">
        <h1 className="header-under-line responsive-text-60 text-white text-center">
          Send Us A Message
        </h1>
        <h2
          className="responsive-text-48 text-center text-white mt-4"
          id="form"
        >
          Tell Us About Yourself And Your Business.
          <br />
          We’d Love To Help You!
        </h2>
        <ContactForm page="contact" />
        <h2 className="responsive-text-48 text-center font-light text-white mt-4">
          Let Us Give You A Demo With No Strings Attached
        </h2>
        <h2 className="responsive-text-48 text-center text-white mt-4">
          You’ll Be Able To See How We Can Help You
          <br />
          Maximize Productivity.
        </h2>
        {/* <button className="custom-btn mt-4">Get Started</button> */}
      </section>
      <Calendar />
    </>
  );
};

export default Leads;
